import React, { useEffect } from "react"
import styled from "styled-components"
import SEO from "../components/global/seo"
import { Link } from "gatsby"

// Components
import { StandardContainer } from "../components/common"

const StyledContainer = styled(StandardContainer)`
  > article {
    padding: 10vh 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin: 0 auto;
    width: 100%;
    max-width: 64rem;
    text-align: center;
  }
  h1 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #f0534c;
  }
  h2 {
    font-size: 1rem;
    line-height: 1.5;
    color: #f0534c;
  }
  a {
    padding: 1rem 3rem;
    border: 1px solid #0d214a;
    border-radius: 0.5rem;
    text-transform: uppercase;
    display: block;
  }
  @media screen and (min-width: 768px) {
    > article {
      padding: 10vh 16rem;
    }
  }
  @media screen and (min-width: 1024px) {
    > article {
      padding: 20vh 20rem;
    }
    h1 {
      font-size: 1rem;
    }
    h2 {
      font-size: 2rem;
    }
  }
`

const NotFound = () => {
  return (
    <>
      <SEO
        title="404: Not Found"
        description="We can't seem to find the page you are looking for."
      />
      <StyledContainer>
        <article>
          <h1>404</h1>
          <h2>We can't seem to find the page you are looking for.</h2>
          <Link to="/">Go back to home</Link>
        </article>
      </StyledContainer>
    </>
  )
}

export default NotFound
